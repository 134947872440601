import React, { useState } from "react";
import { Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UploadRFP = () => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile.type === "application/pdf") {
      setFile(uploadedFile);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const handleContinue = () => {
    navigate("/copilot");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        bgcolor: "#161328",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "500px",
          p: 8,
          bgcolor: "#373548",
          borderRadius: 4,
          boxShadow: 2,
        }}
      >
        <Box
          sx={{
            fontSize: "36px",
            fontFamily: "system-ui",
            color: "white",
            textAlign: "center",
            width: "100%",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          UPLOAD YOUR RFP
        </Box>
        <input
          type="file"
          accept="application/pdf"
          onChange={handleFileChange}
          style={{
            marginTop: "16px",
            color: "white",
            fontWeight: "bold",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          disabled={!file}
          sx={{
            backgroundColor: "#62BD88",
            mt: 2,
            color: "white",
          }}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

export default UploadRFP;
