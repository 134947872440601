import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Copilot from './components/Copilot';
import UploadRFP from './components/UploadRFP';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/upload" element={<UploadRFP />} />
        <Route path="/copilot" element={<Copilot />} />
      </Routes>
    </Router>
  );
}

export default App;
