import React, { useState } from "react";
import { Container, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import QABot from "./QABot";

const GlobalStyles = styled("div")({
  "& html, & body": {
    backgroundColor: "#0A081E",
    margin: 0,
    padding: 0,
  },
});

const StyledOrderedList = styled("ol")(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(2),

  "& li": {
    marginBottom: theme.spacing(1),
  },
}));

const StyledUnorderedList = styled("ul")(({ theme }) => ({
  listStyleType: "disc",
  paddingLeft: theme.spacing(4),
  marginBottom: theme.spacing(2),

  "& li": {
    marginBottom: theme.spacing(1),
  },
}));

const initialQuestions = [
  {
    question: "Summarize the attached RFP",
    answer:
      "This RFP (Request for Proposals) is for a design-build project that involves upgrading the door controls for the Department of Corrections Correctional Treatment Facility in Washington, D.C. The contractor will be responsible for all design, construction, and project management services required to complete the project. The project budget is approximately $1,268,000.00, and the contractor is expected to base their proposal on this amount. The proposal must include a bid/proposal bond, a bid guaranty certification, a subcontracting plan, an EEO policy form, and a certification to furnish performance and payment bonds. The proposal must also be held open for at least 120 days after the RFTOP closing date, and the selected contractor must enter into a contract with the Department within ten days of the award notice.",
  },
  {
    question:
      "I am an engineering firm that needs to submit a proposal for this RFP. Outline in detail all the steps I need to complete to fill out this proposal.",
    answer: (
      <>
        As an engineering firm, you need to complete the following steps to fill
        out the proposal for this RFP:
        <StyledOrderedList>
          <li>
            Review the RFP: Carefully read the RFP and all the attachments
            provided. Ensure that you understand the scope of work, the project
            requirements, and the evaluation criteria.
          </li>
          <li>
            Prepare the Offer Letter: Use Attachment B-Form of Offer Letter and
            Bid Form to prepare the offer letter. Ensure that the offer letter
            includes a commitment to hold the proposal open for at least 120
            days after the RFTOP closing date, and an agreement to enter into a
            contract with the Department within ten days of the notice of award.
          </li>
          <li>
            Prepare the Bid Form: Use Attachment B-Form of Offer Letter and Bid
            Form to prepare the bid form. Ensure that the bid form includes the
            pricing breakdown, budget estimates, and a bid/proposal bond in the
            form of Attachment E.
          </li>
          <li>
            Prepare the Subcontracting Plan: Prepare the Subcontracting Plan as
            set forth in Attachment H. Ensure that the subcontracting plan
            includes the details of the subcontractors and suppliers that the
            Contractor intends to utilize in the performance of the proposed
            Task Order.
          </li>
          <li>
            Prepare the First Source Employment Agreement and Revised Employment
            Plan: Prepare the First Source Employment Agreement and Revised
            Employment Plan as set forth in Attachment I.
          </li>
          <li>
            Submit the EEO Policy Form: Submit an EEO policy form substantially
            in the form of Attachment J.
          </li>
          <li>
            Submit the Certification to Furnish Performance & Payment Bonds:
            Submit a certification to furnish a performance & payment bond
            substantially in the form of Attachment G.
          </li>
          <li>
            Submit the Bid Guaranty Certification: Submit the Bid Guaranty
            Certification (Attachment F).
          </li>
          <li>
            Submit the Tax Affidavit: Submit the Tax Affidavit (Attachment L).
          </li>
          <li>
            Submit the Campaign Finance Form: Submit the Campaign Finance Form
            (Attachment M).
          </li>
          <li>
            Prepare and submit the Project management plan and the preliminary
            schedule: Prepare a Project management plan and a preliminary
            schedule outlining how the Offeror intends to implement the Project,
            key subcontractors, and vendors that will be utilized, and means and
            methods that will be implemented to accomplish the Scope of Work and
            achieve milestones schedule dates required in the RFTOP.
          </li>
        </StyledOrderedList>
      </>
    ),
  },
];

function Copilot() {
  const [userQuestion, setUserQuestion] = useState('');
  const [questions, setQuestions] = useState(initialQuestions);
  const [isLoading, setIsLoading] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const newQuestion = { question: userQuestion, answer: null };
      setQuestions([newQuestion, ...questions]);
      setUserQuestion('');
      setIsLoading(true);
      query(userQuestion).then((response) => {
        console.log('Response is ', response)
        newQuestion.answer = response.response;
        setQuestions([newQuestion, ...questions]);
        setIsLoading(false);
      });
    }
  };

  const query = (question) => {
    // const instanceId = '05bafbf5-df38-4615-bdf1-0d3a9f581f90';
    const instanceId = '6d784fff-816d-4a7f-9ce3-d9fbec499e63'; //bridges rfp generated from running https://colab.research.google.com/drive/1v3EbJSOTQcqGYLSrVbEaJeGGF1dY4lf3#scrollTo=WFavsUReN2b6 script
    const model = 'gpt-4';
    const topK = 50;
    const history = '';

    const apiQuery = `https://api.berri.ai/query?user_email=pradhith.konduri@gmail.com&instance_id=${instanceId}&query=${question}&model=${model}&top_k=${topK}&history=${history}`;

    console.log('API Query: ', apiQuery);

    return fetch(apiQuery).then((response) => response.json());
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <GlobalStyles />
      <Container maxWidth="md">
        <Box
          sx={{
            fontSize: '18px',
            fontFamily: 'system-ui',
            color: 'white',
            textAlign: 'center',
            width: '100%',
            marginTop: '16px',
            marginBottom: '16px'
          }}
        >
          PROPOSAL WRITING COPILOT
        </Box>        
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '10vh',
            backgroundColor: '#373548',
            borderRadius: 4,
            marginBottom: '16px'
          }}
        >
          <TextField
            variant="outlined"
            label="Type your question"
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              style: { color: '#A0AEC0', backgroundColor: '#161328', borderRadius: 4, width: '100%' },
            }}
            InputLabelProps={{ style: { color: '#A0AEC0' } }}
            sx={{ width: '80%' }}
            autoFocus
          />
        </Box>
      </Container>
      {questions.map((item, index) => (
        <Container maxWidth="md" key={index} sx={{ width: '100%',  marginBottom: '16px' }}>
          <QABot
            index={index}
            question={item.question}
            answer={item.answer}
            handleCopy={handleCopy}
            loading={isLoading && index === 0} // only show loading icon for the first question
          />
        </Container>
      ))}
    </>
  );
}

export default Copilot;
