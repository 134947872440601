import React from 'react';
import { Paper, Typography, Divider, Box, IconButton, Snackbar, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const StyledPaper = styled(Paper)({
  borderRadius: 12,
  padding: 16,
  backgroundColor: '#161328',
});

const StyledDivider = styled(Divider)({
  margin: '16px 0',
  backgroundColor: '#A0AEC0',
});

const AnswerBox = styled(Box)({
  backgroundColor: 'rgba(226, 232, 240, 0.16)',
  borderRadius: 12,
  padding: '16px 32px 16px 16px', // added padding on the right
  marginTop: 16,
  marginBottom: 16,
  display: 'flex',
  justifyContent: 'space-between', // added to align items to the right
  alignItems: 'center', // added to center the items vertically
});

const QABot = ({ index, question, answer, handleCopy, loading }) => {
  const [open, setOpen] = React.useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const copyAnswerToClipboard = () => {
    navigator.clipboard.writeText(answer);
    setOpen(true);
  };

  return (
    <StyledPaper elevation={3}>
      <Typography variant="h6" component="h2" gutterBottom style={{ color: '#A0AEC0' }}>
        QUESTION {index + 1}
      </Typography>
      <Typography variant="body1" gutterBottom style={{ color: '#A0AEC0' }}>
        {question}
      </Typography>
      <StyledDivider />
      <Typography variant="h6" component="h2" gutterBottom style={{ color: '#A0AEC0' }}>
        ANSWER
      </Typography>
      <AnswerBox>
        {loading ? (
          <CircularProgress size={24} style={{ color: '#fff' }} />
        ) : (
          <>
            <Typography variant="body1" style={{ color: '#A0AEC0', flexGrow: 1 }}>
              {answer}
            </Typography>
            <IconButton onClick={copyAnswerToClipboard} sx={{ ml: 2 }}>
              <ContentCopyIcon style={{ color: '#fff' }} />
            </IconButton>
          </>
        )}
      </AnswerBox>
      <Snackbar
        open={open}
        autoHideDuration={1000}
        onClose={handleSnackbarClose}
        message="Copied!"
      />
    </StyledPaper>
  );
};

export default QABot;
